/*    All new, 2020 styleguide    */
@import 'styleguide.scss';

/*   FLUID SETTINGS   */
$pc_min_viewport: 320px;
$pc_max_viewport: 1024px;

/*    TYPOGRAPHY   */
/*   h1 font size  */
$pcH1_font_size_min: 30px;
$pcH1_font_size_max: 40px;
/*    h1 line height  */
$pcH1_line_height_min: 35px;
$pcH1_line_height_max: 45px;
/*   h1 margin top */
$pcH1_margin_top_min: 20px;
$pcH1_margin_top_max: 30px;
/*   h1 margin bottom */
$pcH1_margin_bottom_min: 15px;
$pcH1_margin_bottom_max: 25px;
.pcH1 {
  font-weight: 500;
  @include fluid('font-size', $pcH1_font_size_min, $pcH1_font_size_max, $pc_min_viewport, $pc_max_viewport);
  @include fluid('line-height', $pcH1_line_height_min, $pcH1_line_height_max, $pc_min_viewport, $pc_max_viewport);
  @include fluid('margin-top', $pcH1_margin_top_min, $pcH1_line_height_max, $pc_min_viewport, $pc_max_viewport);
  @include fluid('margin-bottom', $pcH1_margin_bottom_min, $pcH1_margin_bottom_max, $pc_min_viewport, $pc_max_viewport);
}
/*   p1 font size */
$pcP1_font_size_min: 14px;
$pcP1_font_size_max: 20px;
/*    h1 line height  */
$pcP1_line_height_min: 16px;
$pcP1_line_height_max: 24px;
/*   h1 margin top */
$pcP1_margin_top_min: 14px;
$pcP1_margin_top_max: 20x;
/*   h1 margin bottom: 20 to 40 */
$pcP1_margin_bottom_min: 14px;
$pcP1_margin_bottom_max: 20px;
.pcP1 {
  font-weight: 400;
  @include fluid('font-size', $pcP1_font_size_min, $pcP1_font_size_max, $pc_min_viewport, $pc_max_viewport);
  @include fluid('line-height', $pcP1_line_height_min, $pcP1_line_height_max, $pc_min_viewport, $pc_max_viewport);
  @include fluid('margin-top', $pcP1_margin_top_min, $pcP1_line_height_max, $pc_min_viewport, $pc_max_viewport);
  @include fluid('margin-bottom', $pcP1_margin_bottom_min, $pcP1_margin_bottom_max, $pc_min_viewport, $pc_max_viewport);
}

.pcA {
  font-weight: 400;
  color: var(--primary);
  @include fluid('font-size', $pcP1_font_size_min, $pcP1_font_size_max, $pc_min_viewport, $pc_max_viewport);
  @include fluid('line-height', $pcP1_line_height_min, $pcP1_line_height_max, $pc_min_viewport, $pc_max_viewport);
}
