@use 'sass:math';

@function strip-unit($value) {
  @return math.div($value, ($value * 0 + 1));
}

@mixin fluid($property_name, $min, $max, $min-vw, $max-vw) {
  & {
    #{$property_name}: $min;
    @media screen and (min-width: $min-vw) {
      #{$property_name}: calc(#{$min} + #{strip_unit($max - $min)} * ((100vw - #{$min-vw}) / #{strip_unit($max-vw - $min-vw)}));
    }
    @media screen and (min-width: $max-vw) {
      #{$property_name}: $max;
    }
  }
}
