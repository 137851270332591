@use 'sass:math';

@import 'variables.scss';
@import './mixins.scss';
@import '../styles/variables.scss';

/*   FLUID MECHANICS    */

/*   Minimum and maximum considered viewport widths  */
$min_vp_width: 500px;
$max_vp_width: 1000px;

@function strip-unit($value) {
  @return math.div($value, ($value * 0 + 1));
}

/*   PLAIN example   */

.normal-h1 {
  font-size: 50px;
  font-weight: semibold;
  margin: 60px 0 40px 0;
}

.normal-p1 {
  font-size: 20px;
  line-height: 1.8;
}

.normal-content-padding {
  padding: 40px 80px;
}

/*   responsive example */

/*  small size */
@media screen and (max-width: 500px) {
  .responsive-h1 {
    font-size: 30px;
    font-weight: semibold;
    color: navy;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .responsive-p1 {
    font-size: 14px;
    line-height: 1.8;
  }
  .responsive-content-padding {
    padding: 20px 40px;
  }
}

/*  medium size */
@media screen and (min-width: 500px) and (max-width: 1000px) {
  .responsive-h1 {
    font-size: 40px;
    font-weight: semibold;
    color: darkred;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .responsive-p1 {
    font-size: 17px;
    line-height: 1.8;
  }
  .responsive-content-padding {
    padding: 30px 60px;
  }
}

/*  large size */
@media screen and (min-width: 1000px) {
  .responsive-h1 {
    font-size: 50px;
    font-weight: semibold;
    color: black;
    margin-top: 60px;
    margin-bottom: 40px;
  }
  .responsive-p1 {
    font-size: 20px;
    line-height: 1.8;
  }
  .responsive-content-padding {
    padding: 40px 80px;
  }
}

/*   h1 minimum and maximum font size   */
$h1_font_size_min: 30px;
$h1_font_size_max: 50px;
/*   h1 margin top: 40 to 60 */
$h1_margin_top_min: 20px;
$h1_margin_top_max: 60px;
/*   h1 margin bottom: 20 to 40 */
$h1_margin_bottom_min: 20px;
$h1_margin_bottom_max: 40px;

.fluid-h1 {
  font-weight: semibold;
  @include fluid('font-size', $h1_font_size_min, $h1_font_size_max, $min_vp_width, $max_vp_width);
  @include fluid('margin-top', 20px, 60px, $min_vp_width, $max_vp_width);
  @include fluid('margin-bottom', $h1_margin_bottom_min, $h1_margin_bottom_max, $min_vp_width, $max_vp_width);
}

/*  p1 min and max font size   */
$p1_font_size_min: 14px;
$p1_font_size_max: 20px;

.fluid-p1 {
  line-height: 1.8;
  @include fluid('font-size', $p1_font_size_min, $p1_font_size_max, $min_vp_width, $max_vp_width);
}

// content padding horizontal
$content_padding_horizontal_min: 20px;
$content_padding_horizontal_max: 80px;
// content padding vertical
$content_padding_vertical_min: 40px;
$content_padding_vertical_max: 40px;

.fluid-content-padding {
  @include fluid(
    'padding-left',
    $content_padding_horizontal_min,
    $content_padding_horizontal_max,
    $min_vp_width,
    $max_vp_width
  );
  @include fluid(
    'padding-right',
    $content_padding_horizontal_min,
    $content_padding_horizontal_max,
    $min_vp_width,
    $max_vp_width
  );

  @include fluid(
    'padding-top',
    $content_padding_vertical_min,
    $content_padding_vertical_max,
    $min_vp_width,
    $max_vp_width
  );
  @include fluid(
    'padding-bottom',
    $content_padding_vertical_min,
    $content_padding_vertical_max,
    $min_vp_width,
    $max_vp_width
  );
}

/*  generic container, can be use in either demo */

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #eee;
  min-height: 100vh;
  padding-top: 20px;
}

.content-paper {
  box-sizing: border-box;
  width: 100%;
  max-width: 900px;
  margin: 20px 0;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.sectionToggle {
  padding: 4px 6px;
  background: #fff;
  margin: 3px;
  font-size: 12px;
  color: #222;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.fluid-text-input {
  @include fluid('font-size', 16px, 20px, $min_vp_width, $max_vp_width);
  @include fluid('padding', 20px, 40px, $min_vp_width, $max_vp_width);
}
