// css custom properties test
:root {
  --primary: #0098a1ff;
  --secondary: #026776;
  --error-red: #f51423;

  // Fonts
  --primary-font: 'source-sans';
  --secondary-font: 'sans-serif';

  // Panel
  --panel: #fff;
  --panel-inactive: rgb(235, 236, 236);
  --panel-text: #202020;
  --panel-text-inactive: #929ba1;
  --top-panel: #fff;

  // Backgrounds
  --background-content: #fafafa;

  // Navigation
  --navigation-top-background: #fff;
  --navigation-item-background: #fff;
  --navigation-hover: #0098a1ff;

  // Alerts
  --alert-low-grad: linear-gradient(to right, #0099a1, #21b28b);
  --alert-medium-grad: linear-gradient(to right, #e0a822, #f5d521);
  --alert-high-grad: linear-gradient(to right, #ff6058, #ff6f45);
}
