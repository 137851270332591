@font-face {
  font-family: 'source-sans';
  src: url('/assets/fonts/source-sans-pro/SourceSansPro-Regular.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'source-sans';
  src: url('/assets/fonts/source-sans-pro/SourceSansPro-Semibold.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'source-sans';
  src: url('/assets/fonts/source-sans-pro/SourceSansPro-Bold.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'source-sans';
  src: url('/assets/fonts/source-sans-pro/SourceSansPro-Italic.ttf') format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}
